"use client";
import React, { useState } from "react";
import Link from "antd/es/typography/Link";
import useSnowflakes from "@components/events/decorate/falldown-snowflake";
import { QrCode } from "@/ui/animate-icons/qr-code";
import { CursorRays } from "@/ui/animate-icons/cursor-rays";
import { LinesY } from "@/ui/animate-icons/lines-y";
import { Gear } from "@/ui/animate-icons/gear";
import { SparklesIcon } from "@/ui/animate-icons/sparkles";

interface IProps {
  url: string;
  title: string;
  icon: "qr-code" | "cursor-rays" | "lines-y" | "sparkles" | "default";
}

const HighlightItem: React.FC<IProps> = ({ url, title, icon }: IProps) => {
  const { snowflakes, createSnowflake } = useSnowflakes(); // Use the custom hook
  const [hovered, setHovered] = useState(false);
  let iconWidget = CursorRays;
  if (icon === "qr-code") {
    iconWidget = QrCode;
  } else if (icon === "lines-y") {
    iconWidget = LinesY;
  } else if (icon === "default") {
    iconWidget = Gear;
  } else if (icon === "sparkles") {
    iconWidget = SparklesIcon;
  }

  return (
    <Link
      href={url}
      onMouseMove={createSnowflake}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      <div className="group flex h-12 w-auto items-center justify-center overflow-hidden rounded-full border px-4 font-semibold text-slate-500">
        {iconWidget({ "data-hovered": hovered, className: "w-5 h-5" })}
        <span className="ml-2 whitespace-nowrap">{title}</span>
      </div>

      {snowflakes}
    </Link>
  );
};

export default HighlightItem;
